import React from 'react';
import { ModalContextType, ModalType, useModalContext } from 'src/hooks/useModal';
import { ScrollModal } from '../../primitives/ScrollModal';
import { PositionModalWrapper } from '../FlowCommons/PositionModalWrapper';
import { WithdrawPositionModalContent } from './WithdrawPositionModalContent';

export const WithdrawPositionModal = () => {
  const { type, args, close } = useModalContext() as ModalContextType<{
    suppliedTokenId: string;
  }>;

  return (
    <ScrollModal open={type === ModalType.WithdrawPosition} setOpen={close}>
      <PositionModalWrapper
        suppliedTokenId={args.suppliedTokenId}
        title="Withdraw"
      >
        {(params) => <WithdrawPositionModalContent {...params} />}
      </PositionModalWrapper>
    </ScrollModal>
  );
};
